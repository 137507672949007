import React from 'react';

import Layout from '../components/Layout';

//import about from '../assets/images/about.jpg';

const IndexPage = () => (
  <Layout activeLink="thanks">
    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="cta-inner text-center rounded">
              <h2 className="section-heading mb-5">
                <span className="section-heading-upper">We'll be in touch</span>
                <span className="section-heading-lower">Thank You</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section cta">
      <div className="container">
        <div className="row">
          <div className="col-xl-9 mx-auto">
            <div className="text-center rounded">
        
              <section>
                <header>
                  {/* <h3>Table</h3> */}
                </header>
                <div className="table-wrapper">
                  <table className="default">
                    <thead>
                      <tr>
                        <th><h1><a href="https://www.facebook.com/Designer-sweet-treats-144017559724157/?tn-str=k*F" target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-square social"></i></a></h1></th>
                        <th><h1><a href="https://www.instagram.com/designer_sweet_treats/?hl=en" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram social"></i></a></h1></th>
                        <th><h1><a href="mailto:jeeranan@designersweettreats.net?subject=New%20Enquiry&body=Please%20tell%20us%20the%20following%20information%3A%0D%0A%0D%0AName%3A%20%0D%0AEvent%20Date%3A%20%0D%0AFlavours%3A%0D%0ANumber%20of%20Servings%3A%0D%0ACake%20Design%3A%0D%0AContact%20Number%3A%0D%0A%0D%0AIf%20possible%20please%20also%20attach%20a%20sketch%20or%20photo%20of%20the%20cake%20you%20would%20like." target="_top"><i class="far fa-envelope social"></i></a></h1></th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </section>
              
            </div>
          </div>
        </div>
      </div>
    </section>

  </Layout>
);

export default IndexPage;
